import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static classes = ["zeroHeight"];
	static targets = ["content", "expandedIndicator"];

	declare expandedIndicatorTarget: HTMLElement;
	declare contentTarget: HTMLElement;
	declare zeroHeightClass: string;

	toggle() {
		this.#rotate();
		const height = this.contentTarget.querySelector("div")?.offsetHeight || 0;
		this.contentTarget.setAttribute("style", `height: ${height}px;`);
		this.contentTarget.classList.toggle(this.zeroHeightClass);
		this.#setContentHeight();
	}

	#rotate() {
		this.expandedIndicatorTarget.classList.toggle("rotate-180");
	}

	#setContentHeight() {
		if (!this.contentTarget.classList.contains(this.zeroHeightClass)) {
			setTimeout(() => {
				this.contentTarget.removeAttribute("style");
			}, 10);
		} else {
			setTimeout(() => {
				this.contentTarget.setAttribute("style", "height: 0px;");
			}, 10);
		}
	}

	connect(): void {
		this.#setContentHeight();
	}
}
