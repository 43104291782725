import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	onClick = (e: Event) => {
		e.preventDefault();
		const dialog = this.dialog();
		if (dialog) {
			document.body.classList.add("overflow-hidden");
			dialog.showModal();
			dialog.addEventListener("close", () => {
				document.body.classList.remove("overflow-hidden");
			});
			dialog
				.querySelector("[data-close='true']")
				?.addEventListener("click", function () {
					dialog.close();
				});
		}
	};

	dialog() {
		const dialogId = (this.element as HTMLButtonElement).dataset.dialogId;
		return document.querySelector(
			`dialog#${dialogId}`
		) as HTMLDialogElement | null;
	}

	connect() {
		if (this.dialog()) {
			this.element.addEventListener("click", this.onClick);
		}
	}
}
