import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["sidebar", "overlay"];
	declare sidebarTarget: HTMLElement;
	declare overlayTarget: HTMLElement;

	toggleMenu() {
		if (this.sidebarTarget.classList.contains("hidden")) {
			this.sidebarTarget.classList.remove("hidden");
		} else {
			this.sidebarTarget.classList.add("slide-out");
			setTimeout(() => {
				this.sidebarTarget.classList.add("hidden");
				this.sidebarTarget.classList.remove("slide-out");
			}, 200);
		}
		this.sidebarTarget.classList.toggle("slide-in");
		this.sidebarTarget.classList.toggle("mt-[64px]");
		this.sidebarTarget.classList.toggle("h-[calc(100%-64px)]");

		this.overlayTarget.classList.toggle("hidden");
	}
}
