import { post } from ".";

interface OverridePasswordResponse {
	error: string;
}

interface OverridePasswordParams {
	password: string;
	overrideType: string;
}

const verifyAuditorOverridePassword = ({
	password,
	overrideType,
}: OverridePasswordParams): Promise<OverridePasswordResponse> => {
	return post("/auditor-override-passwords/verify", {
		password,
		override_type: overrideType,
	}).then((r) => r.response.json<OverridePasswordResponse>());
};

export { verifyAuditorOverridePassword };
