import { Controller } from "@hotwired/stimulus";
import { numberToCurrency } from "formats/strings";

interface FormEvent<T> {
	currentTarget: T;
}

export default class extends Controller {
	static targets = [
		"description",
		"billableHours",
		"quantity",
		"taskRate",
		"price",
		"totalAmount",
		"totalBillableHours",
	];
	declare descriptionTarget: HTMLInputElement;
	declare billableHoursTarget: HTMLInputElement;
	declare quantityTarget: HTMLInputElement;
	declare priceTarget: HTMLInputElement;
	declare taskRateTarget: HTMLSelectElement;
	declare totalAmountTarget: HTMLDivElement;
	declare totalBillableHoursTarget: HTMLDivElement;

	updateTotals() {
		this.totalAmountTarget.innerText = numberToCurrency(
			Number(this.priceTarget.value) * Number(this.quantityTarget.value)
		);
		this.totalBillableHoursTarget.innerText = (
			Number(this.billableHoursTarget.value) * Number(this.quantityTarget.value)
		).toFixed(2);
	}

	updatePrice() {
		const price = this.taskRateTarget.selectedOptions[0].dataset.price || "0";
		this.priceTarget.value = price;
		this.updateTotals();
	}

	callChanged(e: Event) {
		const select = e.currentTarget as HTMLSelectElement;
		const techId = select.selectedOptions[0].dataset.techId || "";
		const name = select.name.replace("call", "tech");
		const input = document.querySelector(
			`[name="${name}"]`
		) as HTMLInputElement | null;
		if (input) {
			input.value = techId;
		}
	}

	updateTaskCodeMetaData({
		currentTarget: { value },
	}: FormEvent<HTMLInputElement>) {
		const opt = document.querySelector(
			`option[value="${value}"]`
		) as HTMLOptionElement;
		if (opt) {
			const description = opt.dataset.description || "";
			this.descriptionTarget.value = description.replace(/\s+/g, " ").trim();

			const billableHours = opt.dataset.billableHours || "0";
			this.billableHoursTarget.value = billableHours;
			this.quantityTarget.value = "1";

			[
				"standardRate",
				"secondaryRate",
				"preferredRate",
				"secondaryValue",
				"ncWarranty",
				"quote",
				"ncOther",
				"overtimeRate",
			].forEach((ot) => {
				const amount = opt.dataset[ot] || "0";
				const option = document.querySelector(
					`option#${ot}`
				) as HTMLOptionElement;
				if (option) {
					const sep = " - ";
					const label = option.innerText.split(sep)[0];
					option.innerText = [label, numberToCurrency(amount)].join(sep);
					option.dataset.price = amount;
				}
			});

			this.taskRateTarget.selectedIndex = 0;
			this.updatePrice();
		}
	}

	connect() {
		this.billableHoursTarget.disabled = true;
		this.descriptionTarget.disabled = true;
		this.element.addEventListener("submit", () => {
			this.billableHoursTarget.disabled = false;
			this.descriptionTarget.disabled = false;
		});
		this.updateTotals();
	}
}
