import { Controller } from "@hotwired/stimulus";

function loadScript(url: string, type = "text/javascript") {
	if (!url) return;

	const existingScript = document.querySelector("script[src*='" + url + "']");
	const head = document.querySelector("head");
	if (!existingScript && head) {
		const script = document.createElement("script");
		script.setAttribute("src", url);
		script.setAttribute("type", type);
		head.appendChild(script);
	}
}

function initMapKit(): Promise<void> {
	return new Promise((resolve) => {
		if (window.mapkit) {
			resolve();
		} else {
			loadScript("https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js");
			const i = setInterval(() => {
				if (window.mapkit) {
					clearInterval(i);
					resolve();
				}
			}, 100);
		}
	});
}

interface AnnotationEvent extends Event {
	annotation: AbcAnnotation;
}

interface AnnotationType {
	latitude: number | string;
	longitude: number | string;
	color?: string;
	title?: string;
	subtitle?: string;
	selected?: boolean;
	glyphText?: string;
	annotation_activity_time?: string;
	data?: Record<string, any>;
}

interface AbcAnnotation extends mapkit.MarkerAnnotation {
	_impl?: Record<string, any>;
}

export default class extends Controller {
	static values = {
		jwt: String,
		annotations: Array,
	};

	readonly timesheetRowOutlets!: any;

	static outlets = ["timesheet-row"];

	declare items: AbcAnnotation[];
	declare jwtValue: string;
	declare annotationsValue: AnnotationType[];

	map: mapkit.Map | undefined = undefined;

	loadMap = () => {
		const jwt = this.jwtValue;
		const mapkit = window.mapkit;
		if (!mapkit) {
			return;
		}

		mapkit.init({
			authorizationCallback: (done) => {
				done(jwt);
			},
		});

		this.map = new mapkit.Map(this.element);
		const items = this.annotationsValue.map((a) => {
			const {
				latitude: latitudeValue,
				longitude: longitudeValue,
				color = "#000000",
				title = "",
				subtitle = "",
				selected = false,
				glyphText = "",
				annotation_activity_time: annotationActivityTime,
			} = a;
			const latitude = Number(latitudeValue);
			const longitude = Number(longitudeValue);

			const coordinate = new mapkit.Coordinate(latitude, longitude);
			const annotation = new mapkit.MarkerAnnotation(coordinate, {
				color,
				title,
				subtitle,
				selected,
				glyphText,
				data: {
					annotationActivityTime,
				},
			});

			return annotation;
		});
		this.items = items;
		this.map.showItems(items);

		// @ts-ignore
		this.map.addEventListener("select", this.handleAnnotationClick.bind(this));
	};

	highlightAnnotationItem(value: string) {
		if (!this.items) return;

		const annotationForClickedRow = this.items.find((annotationItem) => {
			const data = annotationItem?._impl?.data || {};
			const { annotationActivityTime } = data;
			return annotationActivityTime === value;
		});
		if (!annotationForClickedRow) {
			return;
		}
		annotationForClickedRow.selected = true;
		annotationForClickedRow.animates = true;
		this.element.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
	}

	handleAnnotationClick(e: AnnotationEvent) {
		const data = e?.annotation?._impl?.data || {};
		const { annotationActivityTime } = data;
		this.timesheetRowOutlets.forEach((row: any) => {
			if (row.timeValue === annotationActivityTime) {
				row.highlight();
			} else {
				row.removeHighlight();
			}
		});
	}

	disconnect() {
		this.map?.destroy();
	}

	connect() {
		initMapKit().then(this.loadMap);
	}
}
