import { PaymentMethod, Stripe, StripeCardElement } from "@stripe/stripe-js";
import { get, post, patch, destroy } from "api";

interface StripePKResponse {
	publishableKey: string;
}

const publishableKey = (): Promise<StripePKResponse> => {
	return post("/stripe/pk").then((r) => r.response.json<StripePKResponse>());
};

interface StripeCreateIntentResponse {
	paymentIntent: string;
	ephemeralKey: string;
	customer: string;
	errors?: string[];
}

const createPaymentIntent = ({
	amount,
	callId,
	ticketId,
	isClubPaymentMethod = false,
}: {
	amount: number;
	callId?: string;
	ticketId: string;
	isClubPaymentMethod?: boolean;
}): Promise<StripeCreateIntentResponse> => {
	return post("/stripe/payment-intents", {
		amount,
		ticket_id: ticketId,
		call_id: callId,
		is_club_payment_method: isClubPaymentMethod,
	}).then((r) => r.response.json<StripeCreateIntentResponse>());
};

interface CreateStripePaymentParams {
	amount: number;
	cardElement: StripeCardElement;
	stripe: Stripe;
	callId?: string;
	ticketId: string;
	isClubPaymentMethod?: boolean;
	errors?: string[];
}

const createStripePayment = ({
	amount,
	cardElement,
	stripe,
	callId,
	ticketId,
	isClubPaymentMethod = false,
}: CreateStripePaymentParams) => {
	return createPaymentIntent({
		amount,
		callId,
		ticketId,
		isClubPaymentMethod,
	}).then(({ paymentIntent, errors }) => {
		if (errors && errors.length > 0) {
			return Promise.reject({ errors });
		}

		return stripe.confirmCardPayment(paymentIntent, {
			payment_method: {
				card: cardElement,
			},
		});
	});
};

interface StripeCreatePaymentMethodIntentResponse {
	setupIntent: string;
	ephemeralKey: string;
	customer: string;
	errors: string[];
}

const createPaymentMethodIntent = ({
	customerId,
}: {
	customerId: string;
}): Promise<StripeCreatePaymentMethodIntentResponse> => {
	return post(`/customers/${customerId}/payment-method-intents`).then((r) =>
		r.response.json<StripeCreatePaymentMethodIntentResponse>()
	);
};

interface CreateStripePaymentMethodParams {
	customerId: string;
	cardElement: StripeCardElement;
	stripe: Stripe;
}

const createStripePaymentMethod = ({
	customerId,
	cardElement,
	stripe,
}: CreateStripePaymentMethodParams) => {
	return createPaymentMethodIntent({ customerId }).then(
		({ setupIntent, errors }) => {
			if (errors.length) {
				return {
					errors,
					stripeResponse: undefined,
				};
			}

			return {
				errors,
				stripeResponse: stripe.confirmCardSetup(setupIntent, {
					payment_method: {
						card: cardElement,
					},
				}),
			};
		}
	);
};

const updateCustomerPaymentMethodId = ({
	customerId,
	paymentMethodId,
}: {
	customerId: string;
	paymentMethodId: string;
}) => {
	return patch(`/customers/${customerId}`, {
		payment_method_id: paymentMethodId,
	}).then((r) => r.response.json());
};

interface GetUacPaymentMethodResponse {
	paymentMethod: PaymentMethod;
}

const getUacPaymentMethod = (
	customerId: string
): Promise<GetUacPaymentMethodResponse> => {
	return get(`/stripe/customers/${customerId}/uac-payment-method`).then((r) =>
		r.response.json<GetUacPaymentMethodResponse>()
	);
};

interface DeletePaymentMethodResponse {
	errors: string[];
}

const deleteStripePaymentMethod = (
	paymentMethodId: string
): Promise<DeletePaymentMethodResponse> => {
	return destroy(`/stripe/payment-methods/${paymentMethodId}`).then((r) =>
		r.response.json<DeletePaymentMethodResponse>()
	);
};

const deleteStripeBankAccount = (
	customerId: string,
	bankAccountId: string
): Promise<DeletePaymentMethodResponse> => {
	return destroy(`/stripe/customers/${customerId}/remove-bank-account`, {
		bank_account_id: bankAccountId,
	}).then((r) => r.response.json<DeletePaymentMethodResponse>());
};

function loadScript(url: string, type = "text/javascript") {
	if (!url) return;

	const existingScript = document.querySelector("script[src*='" + url + "']");
	const head = document.querySelector("head");
	if (!existingScript && head) {
		const script = document.createElement("script");
		script.setAttribute("src", url);
		script.setAttribute("type", type);
		head.appendChild(script);
	}
}

const initializeStripe = () => {
	loadScript("https://js.stripe.com/v3/");
};

function copyCard(e: Event) {
	e.preventDefault();
	const button = e.currentTarget as HTMLButtonElement;
	const card = button.querySelector("img")?.dataset.card || "";
	let cardNumber = "4242 4242 4242 4242";
	switch (card) {
		case "discover":
			cardNumber = "6011 1111 1111 1117";
			break;
		case "mastercard":
			cardNumber = "5555 5555 5555 4444";
			break;
		case "amex":
			cardNumber = "378 2822 4631 0005";
			break;
		case "auth":
			cardNumber = "4000 0025 0000 3155";
			break;
		case "decline":
			cardNumber = "4000 0000 0000 9995";
			break;
	}
	navigator.clipboard.writeText(cardNumber.trim());
	window.toast({ text: `${card} is on the clipboard`, type: "info" });
}

export {
	publishableKey,
	createPaymentIntent,
	createStripePayment,
	getUacPaymentMethod,
	createStripePaymentMethod,
	deleteStripePaymentMethod,
	deleteStripeBankAccount,
	updateCustomerPaymentMethodId,
	initializeStripe,
	copyCard,
};
