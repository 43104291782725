const freeze = () => {
	// @ts-ignore
	window.Turbo.navigator.currentVisit.scrolled = true;
	document.removeEventListener("turbo:render", freeze);
};

export const freezeScrollOnNextRender = () => {
	document.addEventListener("turbo:render", freeze);
};

export function debounce(func: () => void, timeout = 300) {
	let timer: NodeJS.Timeout | undefined;
	// @ts-ignore
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			// @ts-ignore
			func.apply(this, args);
		}, timeout);
	};
}

export function pluralize(amount: number, plural: string, singular: string) {
	return `${amount} ${amount === 1 ? singular : plural}`;
}
