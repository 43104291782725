import { Controller } from "@hotwired/stimulus";
import { getUacPaymentMethod } from "api/stripe";
import { capitalize } from "formats/strings";

const CARD_TYPES = [
	"amex",
	"bank",
	"bank_of_america",
	"chase",
	"citibank",
	"credit_card",
	"discover",
	"mastercard",
	"visa",
];

const MONTHS = [
	"",
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

export default class extends Controller {
	static targets = ["cardDetails", "loading"];
	static values = { customerId: String };

	declare cardDetailsTarget: HTMLElement;
	declare loadingTarget: HTMLElement;
	declare customerIdValue: string;

	onViewPress(e: Event) {
		this.#toggleLoading();
		getUacPaymentMethod(this.customerIdValue).then(({ paymentMethod }) => {
			this.#resetCardDetails();
			this.#toggleLoading();

			if (paymentMethod) {
				this.cardDetailsTarget.classList.remove("hidden");
				const brandImageName = this.#brand(paymentMethod.card?.brand || "");
				const brandName = capitalize(paymentMethod.card?.brand || "");
				const last4 = paymentMethod.card?.last4 || "";
				const expireMonth = MONTHS[paymentMethod.card?.exp_month || 0];
				const expireYear = paymentMethod.card?.exp_year || "";

				this.#enableImage(brandImageName);
				const numberDiv = this.cardDetailsTarget.querySelector(
					"div[data-id='card-number']"
				);
				if (numberDiv) {
					numberDiv.appendChild(
						this.#cardDetails({
							brandName,
							expireMonth,
							expireYear,
							last4,
						})
					);
				}
			} else {
				window.toast({ text: "Could not load card details", type: "error" });
			}
		});
	}

	#brand = (bank: string) => {
		let brand = CARD_TYPES.includes(bank) ? bank : "credit_card";
		if (/citi/i.test(bank)) {
			brand = "citibank";
		}
		if (/bank of america/i.test(bank)) {
			brand = "bank_of_america";
		}
		if (/boa/i.test(bank)) {
			brand = "bank_of_america";
		}

		return brand;
	};

	#toggleLoading() {
		this.loadingTarget.classList.toggle("hidden");
	}

	#resetCardDetails() {
		const div = this.cardDetailsTarget.querySelector(
			"div[data-id='card-number']"
		);
		if (div) {
			div.innerHTML = "";
		}
	}

	#enableImage(brand: string) {
		this.cardDetailsTarget.querySelectorAll("img").forEach((img) => {
			if (!img.classList.contains("hidden")) {
				img.classList.add("hidden");
			}

			if (img.dataset.card === brand) {
				img.classList.remove("hidden");
			}
		});

		this.cardDetailsTarget.querySelectorAll("img");
	}

	#cardDetails({
		brandName,
		expireMonth,
		expireYear,
		last4,
	}: {
		brandName: string;
		expireMonth: string | number;
		expireYear: string | number;
		last4: string;
	}) {
		const div = document.createElement("div");
		const brand = document.createElement("div");
		brand.classList.add("text-sm", "font-medium");
		brand.innerText = `${brandName} •••• ${last4}`;

		const expire = document.createElement("div");
		expire.classList.add("text-sm", "text-slate-500");
		expire.innerText = `Expires ${expireMonth} ${expireYear}`;

		div.appendChild(brand);
		div.appendChild(expire);
		return div;
	}
}
