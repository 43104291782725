import { Controller } from "@hotwired/stimulus";
import { formatPhoneNumber, unformatPhoneNumber } from "formats/strings";

interface LowestTechIdsMap {
	[key: string]: string;
}

export default class extends Controller {
	static values = {
		comfortConsultDepartments: Array,
		salesRepTechType: String,
		lowestTechIds: Object,
		techPersisted: Boolean,
	};
	static targets = [
		"department",
		"employeeId",
		"payWarning",
		"phone",
		"submit",
		"techId",
		"techType",
		"lowestTechIdInfo",
	];

	declare comfortConsultDepartmentsValue: string[];
	declare lowestTechIdsValue: LowestTechIdsMap;
	declare salesRepTechTypeValue: string;
	declare techPersistedValue: boolean;

	declare departmentTarget: HTMLInputElement;
	declare employeeIdTarget: HTMLInputElement;
	declare payWarningTargets: [HTMLInputElement];
	declare phoneTarget: HTMLInputElement;
	declare submitTarget: HTMLInputElement;
	declare techIdTarget: HTMLInputElement;
	declare techTypeTarget: HTMLInputElement;
	declare lowestTechIdInfoTarget: HTMLInputElement;

	initialize(): void {
		this.formatPhone();
	}

	verifyComfortConsultantTechType() {
		const isCCDept = this.comfortConsultDepartmentsValue.includes(
			this.departmentTarget.value
		);
		const techTypeNotSales =
			this.techTypeTarget.value !== this.salesRepTechTypeValue;

		if (isCCDept && techTypeNotSales) {
			window
				.confirmAction(
					"You've selected Comfort Consultation for this tech's department. Should their tech type be sales rep?"
				)
				.then((didConfirm) => {
					if (didConfirm) {
						this.techTypeTarget.value = this.salesRepTechTypeValue;
					}
					this.updatePayWarnings();
				});
		}
	}

	updatePayWarnings() {
		this.payWarningTargets.forEach((element) => {
			element.hidden =
				element.dataset["payWarningFor"] !== this.techTypeTarget.value;
		});
	}

	setLowestAvailableTechId() {
		if (this.techPersistedValue) return;

		const minTechId = this.lowestTechIdsValue[this.departmentTarget.value];
		if (minTechId) {
			this.techIdTarget.value = minTechId;
			this.lowestTechIdInfoTarget.hidden = false;
		}
	}

	clearLowestTechIdInfo() {
		this.lowestTechIdInfoTarget.hidden = true;
	}

	formatPhone() {
		const enteredPhone = this.phoneTarget.value;
		this.phoneTarget.value = formatPhoneNumber(enteredPhone) || enteredPhone;
	}

	formatEmployeeId() {
		this.employeeIdTarget.value = this.employeeIdTarget.value.toUpperCase();
	}

	onSubmitClick = (e: Event) => {
		e.preventDefault();
		this.submitTarget.setAttribute("disabled", "");
		this.phoneTarget.value = unformatPhoneNumber(this.phoneTarget.value);
		this.submitTarget.closest("form")?.requestSubmit();
	};
}
