export function capitalize(s: string) {
	return s.replace(/^\w/, (c) => c.toUpperCase());
}

export function numberToCurrency(value: string | number) {
	return Number(value).toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
	});
}

export function formatPhoneNumber(phoneNumberString: string) {
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		return "(" + match[1] + ") " + match[2] + "-" + match[3];
	}
	return null;
}

export function unformatPhoneNumber(phoneNumberString: string) {
	return phoneNumberString.replace(/\D/g, "");
}
