import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = {
		title: String,
	};

	declare titleValue: string;

	closeDialog(e: Event) {
		e.preventDefault();
		this.element.closest("dialog")?.close();
	}

	openDialog = (e: Event) => {
		const link = e.currentTarget as HTMLAnchorElement;
		const temp = document.importNode(
			(document.getElementById("link-dialog-modal") as HTMLTemplateElement)
				?.content,
			true
		);

		if (!temp) return;

		e.preventDefault();

		const dialog = temp.querySelector("dialog") as HTMLDialogElement;

		document.body.classList.add("overflow-hidden");
		dialog.addEventListener("close", () => {
			document.body.classList.remove("overflow-hidden");
			dialog.remove();
		});

		dialog.setAttribute("data-controller", "link-dialog");

		const titleEl = temp.querySelector(
			"[data-page-title]"
		) as HTMLParagraphElement;
		const title = this.titleValue || link.innerText;
		titleEl.setAttribute("data-page-title", title);
		titleEl.innerText = title;

		const frame = temp.querySelector("[data-frame='true']");
		if (frame) {
			frame.setAttribute("id", link.dataset.turboFrameId || "");
			frame.setAttribute("src", link.href);
		}

		document.body.appendChild(dialog);
		dialog.showModal();
	};
}
