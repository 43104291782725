import { Controller } from "@hotwired/stimulus";
import { debounce, freezeScrollOnNextRender } from "utils";

export default class extends Controller {
	static targets = ["saveDraft", "overridePassword", "form"];
	static values = {
		passwordRequired: Boolean,
	};

	declare formTarget: HTMLFormElement;
	declare saveDraftTarget: HTMLButtonElement;
	declare overridePasswordTarget: HTMLInputElement;
	declare passwordRequiredValue: boolean;
	declare saveDraft: () => void;

	initialize(): void {
		this.saveDraft = debounce(() => {
			freezeScrollOnNextRender();
			this.saveDraftTarget.click();
		}, 1000);
	}

	closeDialog(e: Event) {
		if (!window.location.pathname.endsWith("new")) {
			e.preventDefault();
			this.element.closest("dialog")?.close();
		}
	}

	saveDraftPress() {
		const oldValue = this.passwordRequiredValue;
		this.passwordRequiredValue = false;

		setTimeout(() => {
			this.passwordRequiredValue = oldValue;
		}, 200);
	}

	verifyPassword(e: Event) {
		if (this.passwordRequiredValue) {
			const password = prompt(
				"Not all payments are approved. Please provide an override password:"
			);

			if (password) {
				this.overridePasswordTarget.value = password;
				(this.element as HTMLDivElement).dataset.turbo = "false";
			} else {
				e.preventDefault();
			}
		}
	}

	connect() {
		this.overridePasswordTarget.closest("div")?.classList.add("hidden");
	}
}
