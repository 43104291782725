import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = {
		url: String,
	};

	declare urlValue: string;

	connect() {
		if (!document.documentElement.hasAttribute("data-turbo-preview")) {
			window.Turbo.visit(this.urlValue);
		}
	}
}
