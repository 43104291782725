import { Controller } from "@hotwired/stimulus";

type Item = {
	value: string;
	description: string;
};

type SearchInputChangeEvent = {
	target: {
		value: string;
	};
};

type ToggleItemEvent = {
	params: {
		value: string;
	};
};

export default class extends Controller {
	static targets = ["select", "searchInput", "results", "selectedContainer"];

	static values = {
		items: Array,
	};

	declare readonly resultsTarget: HTMLElement;
	declare readonly selectedContainerTarget: HTMLElement;
	declare readonly searchInputTarget: HTMLInputElement;
	declare readonly selectTarget: HTMLSelectElement;
	declare itemsValue: Item[];

	selectTargetConnected(target: HTMLSelectElement) {
		Array.from(target.options)
			.filter((o) => o.selected)
			.forEach(({ value }) => {
				this.selectedContainerTarget.insertAdjacentHTML(
					"beforeend",
					this.renderBadgeItem(value)
				);
			});
	}

	showResults() {
		this.resultsTarget.classList.remove("hidden");
		this.resultsTarget.innerHTML = this.itemsValue
			.map(this.renderResultItem)
			.join("\n");
	}

	handleEscPress(event: InputEvent) {
		event.preventDefault();

		this.hideResults();

		(event.target as HTMLInputElement).blur();
	}

	updateResults({ target: { value: query } }: SearchInputChangeEvent) {
		if (query != "") {
			this.resultsTarget.classList.remove("hidden");
			const searchResults = this.itemsValue
				.filter((item) => !this.currentSelectedValues.includes(item.value)) // exclude currently selected values
				.filter(
					(item) =>
						this.substrMatch(item.value, query) ||
						this.substrMatch(item.description, query)
				); // find substr matches for query value

			if (searchResults.length) {
				this.resultsTarget.innerHTML = searchResults
					.map(this.renderResultItem)
					.join("\n");
			} else {
				this.resultsTarget.innerHTML = `
					<div class="flex flex-col items-center p-8 gap-1">
						<div>No results for '${query}'</div>
					</div>
				`;
			}
		} else {
			this.hideResults();
		}
	}

	get currentSelectedValues() {
		return Array.from(this.selectTarget.options)
			.filter((o) => o.selected)
			.map((o) => o.value);
	}

	removeItem({ params: { value } }: ToggleItemEvent) {
		this.toggleHiddenSelectValue(value);

		const removeTarget = this.badgeId(value);
		document.getElementById(removeTarget)?.remove();
	}

	badgeId(value: string) {
		return `badge${value}`;
	}

	selectItem({ params: { value } }: ToggleItemEvent) {
		this.toggleHiddenSelectValue(value);
		this.searchInputTarget.value = "";
		this.hideResults();
		this.selectedContainerTarget.insertAdjacentHTML(
			"beforeend",
			this.renderBadgeItem(value)
		);
	}

	toggleHiddenSelectValue(value: string) {
		const optionByValue = Array.from(this.selectTarget.options).find(
			(option) => option.value == value
		);

		if (!optionByValue) {
			console.warn("multiselect - couldn't find option by value " + value);
			return;
		}

		optionByValue.selected = !optionByValue.selected;
		this.selectTarget.dispatchEvent(new Event("change"));
	}

	renderBadgeItem(value: string) {
		const badgeId = this.badgeId(value);
		const description = this.itemsValue.find(
			(item) => item.value === value
		)?.description;

		return `
			<span class="inline-flex items-center gap-x-0.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700" id="${badgeId}">
				${description}
			  <button type="button" role="button" class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20" data-action="click->multiselect#removeItem" data-multiselect-value-param="${value}">
			    <svg viewBox="0 0 14 14" class="h-3.5 w-3.5 stroke-blue-800/50 group-hover:stroke-blue-800/75">
			      <path d="M4 4l6 6m0-6l-6 6" />
			    </svg>
			    <span class="absolute -inset-1"></span>
			  </button>
			</span>	
		`;
	}

	renderResultItem({ value, description }: Item) {
		return `
			<button type="!block button" role="button" data-action="click->multiselect#selectItem" data-multiselect-value-param="${value}" title="Add ${value}">
				<b>${value}</b> - ${description}
			</button>
		`;
	}

	hideResults() {
		this.resultsTarget.classList.add("hidden");
		this.resultsTarget.innerHTML = "";
	}

	substrMatch(string: string | undefined, query: string) {
		if (!string) return false;

		return string.toLowerCase().includes(query.toLowerCase());
	}
}
