import { Controller } from "@hotwired/stimulus";
import { debounce } from "utils";

export default class extends Controller {
	static targets = ["searchForm"];
	declare searchFormTarget: HTMLFormElement;
	declare debouncedSubmit: () => void;

	initialize(): void {
		this.debouncedSubmit = debounce(() => {
			this.searchFormTarget.requestSubmit();
		});
	}

	submit() {
		this.debouncedSubmit();
	}
}
