import { Controller } from "@hotwired/stimulus";
import { Consumer } from "@rails/actioncable";
import { pluralize } from "utils";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
	static targets = ["count"];
	static values = {
		id: String,
	};
	declare idValue: string;
	declare countTarget: HTMLDivElement;
	declare consumer: Consumer;

	updateCount = (amount: number) => {
		if (amount <= 1) {
			this.countTarget.classList.add("hidden");
		} else {
			this.countTarget.classList.remove("hidden");
			const text = this.countTarget.querySelector("#text") as HTMLSpanElement;
			text.innerText = `${pluralize(
				amount,
				"users",
				"user"
			)} viewing this order`;
		}
	};

	connect = () => {
		const that = this;

		this.consumer = createConsumer();
		this.consumer.subscriptions.create(
			{
				channel: "PartOrdersChannel",
				room: that.idValue,
			},
			{
				received(data: { amount_present: number }) {
					that.updateCount(data.amount_present);
				},
			}
		);
	};

	disconnect(): void {
		this.consumer.disconnect();
	}
}
