import { Controller } from "@hotwired/stimulus";
import QRCode from "qrcode";

export default class extends Controller {
	static targets = ["qrCode", "updatedOn", "expiresOn", "download"];

	declare qrCodeTarget: HTMLAnchorElement;
	declare updatedOnTarget: HTMLElement;
	declare expiresOnTarget: HTMLElement;
	declare downloadTarget: HTMLAnchorElement;

	change(e: Event) {
		const v = e.currentTarget as HTMLInputElement;
		this.updatedOnTarget.innerText = `Last updated on ${v.dataset.updatedAt}`;
		this.expiresOnTarget.innerText = `Expires on ${v.dataset.expiresAt}`;
		this.downloadTarget.href = v.dataset.buildUrl || "";
		this.qrCodeTarget.href = v.dataset.qrUrl || "";
		const img = this.qrCodeTarget.querySelector("img");
		if (img) {
			QRCode.toDataURL(v.dataset.qrUrl || "")
				.then((url) => {
					img.src = url;
				})
				.catch(() => {});
		}
	}

	connect() {
		(document.querySelector("input[name=app]") as HTMLElement | null)?.click();
	}
}
