import { Controller } from "@hotwired/stimulus";
import QRCode from "qrcode";

export default class extends Controller {
	connect() {
		const url = this.element.getAttribute("href");
		const img = this.element.querySelector("img");
		if (url && img) {
			QRCode.toDataURL(url)
				.then((url) => {
					img.src = url;
				})
				.catch(() => {});
		}
	}
}
