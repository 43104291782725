import { Controller } from "@hotwired/stimulus";

const SESSION_KEY = "_abc_session";
const USER_KEY = "_abc_user";

export default class extends Controller {
	onPress() {
		this.removeSessionToken().then(() => {
			//@ts-ignore
			window.location = "/login";
		});
	}

	removeSessionToken() {
		return new Promise((res, rej) => {
			try {
				localStorage.removeItem(SESSION_KEY);
				localStorage.removeItem(USER_KEY);
				res(undefined);
			} catch {
				rej();
			}
		});
	}
}
