import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["row"];
	declare rowTarget: HTMLAnchorElement;

	connect() {
		const rowTarget = this.rowTarget;
		this.element.addEventListener("click", function (e: Event) {
			e.preventDefault();
			window.Turbo.visit(rowTarget.href);
		});
	}
}
