import {
	put as rput,
	get as rget,
	post as rpost,
	patch as rpatch,
	destroy as rdestroy,
} from "@rails/request.js";

const base = (
	path: string,
	method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
	headers = {},
	body: object | FormData = {}
) => {
	let req: RequestInit = {
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			...headers,
		},
		credentials: "same-origin",
		method,
	};

	if (method !== "GET") {
		// @ts-ignore
		switch (req.headers["Content-Type"]) {
			case "application/json":
				req.body = JSON.stringify(body);
				break;
			case "multipart/form-data":
				// @ts-ignore
				delete req.headers["Content-Type"];
				req.body = body as FormData;
				break;
			default:
				// @ts-ignore
				req.body = body;
		}
	}

	switch (method) {
		case "GET":
			return rget(path, req);
		case "POST":
			return rpost(path, req);
		case "PUT":
			return rput(path, req);
		case "PATCH":
			return rpatch(path, req);
		case "DELETE":
			return rdestroy(path, req);
	}
};

export function get(path: string, body = {}, headers = {}) {
	return base(path, "GET", headers, body);
}

export function post(path: string, body: object = {}, headers = {}) {
	return base(path, "POST", headers, body);
}

export function patch(path: string, body: object = {}, headers = {}) {
	return base(path, "PATCH", headers, body);
}

export function destroy(path: string, body: object = {}, headers = {}) {
	return base(path, "DELETE", headers, body);
}
