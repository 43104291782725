import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	clicked = false;

	onClick = (e: Event) => {
		if (this.clicked) {
			e.preventDefault();
			return;
		}

		this.clicked = true;
		const t = e.target as HTMLAnchorElement;
		const disableWith = t.dataset.with;
		const button = t.innerHTML;
		if (disableWith) {
			t.classList.add("opacity-50");
			t.innerText = disableWith;
		}
		setTimeout(() => {
			this.clicked = false;
			t.classList.remove("opacity-50");
			t.innerHTML = button;
		}, 15000);
	};

	connect() {
		this.element.addEventListener("click", this.onClick);
	}
}
