import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="controller"
export default class extends Controller {
	static values = {
		time: String,
	};

	declare timeValue: string;
	readonly mapkitOutlet!: any;
	static outlets = ["mapkit"];

	highlight() {
		this.element.scrollIntoView({
			behavior: "smooth",
			block: "center",
		});
		this.element.classList.add("!bg-blue-200");
	}

	removeHighlight() {
		this.element.classList.remove("!bg-blue-200");
	}

	handleRowClick(e: any) {
		this.mapkitOutlet.highlightAnnotationItem(this.timeValue);
	}
}
