import { Controller } from "@hotwired/stimulus";
import {
	StripeConstructor,
	StripeCardElement,
	StripeCardElementOptions,
	StripeElements,
} from "@stripe/stripe-js";
import {
	copyCard,
	createStripePaymentMethod,
	initializeStripe,
	publishableKey,
	updateCustomerPaymentMethodId,
} from "api/stripe";

interface DialogElement extends HTMLElement {
	showModal(): void;
	close(): void;
}

const cardOptions: StripeCardElementOptions = {
	hidePostalCode: true,
	style: {
		base: {
			fontSize: "14px",
			fontFamily: "",
			color: "#000",
			"::placeholder": {
				color: "rgba(0, 0, 0, 0.42)",
			},
		},
		invalid: {
			color: "red",
		},
	},
};

export default class extends Controller {
	static targets = [
		"loading",
		"newPaymentMethod",
		"dialog",
		"submitPayment",
		"closeModal",
	];
	static values = {
		customerId: String,
		submitting: Boolean,
	};

	// Targets
	declare loadingTarget: HTMLElement;
	declare newPaymentMethodTarget: HTMLElement;
	declare dialogTarget: DialogElement;
	declare submitPaymentTarget: DialogElement & HTMLButtonElement;
	declare closeModalTarget: DialogElement & HTMLButtonElement;

	// Values
	declare customerIdValue: string;
	declare submittingValue: boolean;

	// Form values
	declare stripeElements?: StripeElements;
	declare stripe?: ReturnType<StripeConstructor>;
	declare cardElement?: StripeCardElement;

	copyCard(e: Event) {
		copyCard(e);
	}

	addPaymentMethod() {
		initializeStripe();
		if (window.Stripe) {
			this.dialogTarget.showModal();
			this.dialogTarget.classList.add("fadeIn");
			publishableKey().then((k) => {
				if (window.Stripe) {
					this.stripe = window.Stripe(k.publishableKey)!;
					this.stripeElements = this.stripe.elements();
					this.cardElement = this.stripeElements.create("card", cardOptions);
					this.cardElement.mount("#card-element");
				}
			});
		} else {
			setTimeout(this.addPaymentMethod.bind(this), 300);
		}
	}

	close() {
		this.dialogTarget.classList.remove("fadeIn");
		this.dialogTarget.close();
	}

	submitPaymentMethod(e: Event) {
		e.preventDefault();
		const customerId = this.customerIdValue;

		if (this.stripeElements && this.stripe && this.cardElement) {
			this.submittingValue = true;
			return createStripePaymentMethod({
				cardElement: this.cardElement,
				stripe: this.stripe,
				customerId,
			})
				.then(({ errors, stripeResponse }) => {
					if (errors.length) {
						return Promise.reject(errors.map((text) => new Error(text)));
					}

					return stripeResponse;
				})
				.then((r) => {
					if (r && r.error?.message) {
						return Promise.reject(r.error);
					}

					const paymentMethodId = r?.setupIntent?.payment_method as string;
					if (paymentMethodId) {
						updateCustomerPaymentMethodId({ customerId, paymentMethodId }).then(
							() => {
								const text = this.loadingTarget.querySelector("span");
								if (text) {
									text.innerText = "Finalizing details";
								}
								setTimeout(() => {
									window.toast({ type: "notice", text: "Card Updated" });
								}, 6000);
								setTimeout(() => {
									window.Turbo.visit(window.location.pathname);
								}, 5000);
							}
						);
					}
				})
				.catch((e) => {
					this.submittingValue = false;
					if (Array.isArray(e)) {
						e.forEach(({ message }) => {
							if (message) {
								window.toast({ type: "error", text: message });
							}
						});
						return;
					}
					if (e.message) {
						window.toast({ type: "error", text: e.message });
					}
				});
		}

		return Promise.reject([new Error("Something went wrong")]);
	}

	submittingValueChanged() {
		try {
			if (!this.dialogTarget) return;
		} catch {
			return;
		}

		if (this.submittingValue) {
			this.closeModalTarget.disabled = true;
			this.closeModalTarget.classList.add("hidden");
			this.submitPaymentTarget.classList.add("hidden");
			this.loadingTarget.classList.remove("hidden");
		} else {
			this.closeModalTarget.disabled = false;
			this.closeModalTarget.classList.remove("hidden");
			this.submitPaymentTarget.classList.remove("hidden");
			this.loadingTarget.classList.add("hidden");
		}
	}

	connect() {
		if (window.location.search.includes("update_club=true")) {
			this.addPaymentMethod();
		}
	}
}
