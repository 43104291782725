import { Controller } from "@hotwired/stimulus";
import {
	StripeCardElement,
	StripeCardElementOptions,
	StripeConstructor,
	StripeElements,
} from "@stripe/stripe-js";
import {
	copyCard,
	createStripePayment,
	initializeStripe,
	publishableKey,
	updateCustomerPaymentMethodId,
} from "api/stripe";

const cardOptions: StripeCardElementOptions = {
	hidePostalCode: true,
	style: {
		base: {
			fontSize: "14px",
			fontFamily: "",
			color: "#000",
			"::placeholder": {
				color: "rgba(0, 0, 0, 0.42)",
			},
		},
		invalid: {
			color: "red",
		},
	},
};

export default class extends Controller {
	static targets = [
		"checkNumberContainer",
		"loading",
		"stripeContainer",
		"clubPayment",
		"paymentType",
		"callId",
		"price",
	];

	static values = {
		customerId: String,
		ticketId: String,
	};

	declare checkNumberContainerTarget: HTMLDivElement;
	declare clubPaymentTarget: HTMLInputElement;
	declare callIdTarget: HTMLSelectElement;
	declare priceTarget: HTMLInputElement;
	declare stripeContainerTarget: HTMLDivElement;
	declare paymentTypeTarget: HTMLSelectElement;
	declare loadingTarget: HTMLElement;

	// Values
	declare customerIdValue: string;
	declare ticketIdValue: string;

	// Form values
	declare stripeElements?: StripeElements;
	declare stripe?: ReturnType<StripeConstructor>;
	declare cardElement?: StripeCardElement;

	connect() {
		initializeStripe();
		this.setupCard();
	}

	loading() {
		this.element
			.querySelector("input[type=submit]")
			?.setAttribute("disabled", "");
		this.loadingTarget.classList.remove("hidden");
	}

	removeLoading() {
		this.loadingTarget.classList.add("hidden");
		this.element
			.querySelector("input[type=submit]")
			?.removeAttribute("disabled");
	}

	submit(e: Event) {
		if (this.paymentTypeTarget.value === "CCard") {
			e.preventDefault();
			this.loading();
			this.submitPayment();
		}
	}

	submitPayment() {
		const amount = Number((Number(this.priceTarget.value) * 100).toFixed());
		const callId = this.callIdTarget.value;
		const isClubPaymentMethod = this.clubPaymentTarget.checked;
		const ticketId = this.ticketIdValue;
		const customerId = this.customerIdValue;

		if (this.stripeElements && this.stripe && this.cardElement) {
			return createStripePayment({
				amount,
				cardElement: this.cardElement,
				stripe: this.stripe,
				callId,
				ticketId,
				isClubPaymentMethod,
			})
				.then((stripeResponse) => {
					if (stripeResponse.error?.message) {
						throw stripeResponse.error;
					}
					const paymentMethodId = stripeResponse.paymentIntent
						?.payment_method as string | undefined | null;
					if (isClubPaymentMethod && paymentMethodId && customerId) {
						updateCustomerPaymentMethodId({
							customerId,
							paymentMethodId,
						})
							.catch((e) => {
								this.removeLoading();
								window.toast({
									type: "error",
									text: "Could not save Club payment method",
								});
							})
							.finally(() => {
								this.visitTicket();
							});
					} else {
						this.visitTicket();
					}
				})
				.catch((e) => {
					this.removeLoading();
					if ("message" in e) {
						window.toast({ text: e.message, type: "error" });
					}

					if (e.errors && e.errors.length > 0) {
						window.toast({ text: e.errors.join("\n"), type: "error" });
					}
				});
		}

		return Promise.reject();
	}

	visitTicket() {
		window.Turbo.visit(
			window.location.pathname.replace("/payments/new", "/payments/success")
		);
	}

	setupCard() {
		if (window.Stripe) {
			publishableKey().then((k) => {
				if (window.Stripe) {
					this.stripe = window.Stripe(k.publishableKey)!;
					this.stripeElements = this.stripe.elements();
					this.cardElement = this.stripeElements.create("card", cardOptions);
					this.cardElement.mount("#card-element");
				}
			});
		} else {
			setTimeout(this.setupCard.bind(this), 500);
		}
	}

	copyCard(e: Event) {
		copyCard(e);
	}

	paymentTypeChanged() {
		if (this.paymentTypeTarget.value === "Check") {
			this.checkNumberContainerTarget.classList.remove("hidden");
		} else {
			this.checkNumberContainerTarget.classList.add("hidden");
		}

		if (this.paymentTypeTarget.value === "CCard") {
			this.stripeContainerTarget.classList.remove("hidden");
		} else {
			this.stripeContainerTarget.classList.add("hidden");
		}
	}
}
