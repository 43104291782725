import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submit"
export default class extends Controller {
	static targets = ["submitButton"];

	declare readonly submitButtonTarget: HTMLButtonElement;

	submit() {
		this.form.requestSubmit(this.submitButtonTarget);
	}

	get form() {
		return this.element as HTMLFormElement;
	}
}
