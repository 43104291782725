import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["descriptionRequired"];
	declare descriptionRequiredTarget: HTMLInputElement;

	onDefaultDescriptionChange() {
		this.descriptionRequiredTarget.checked = true;
	}
}
