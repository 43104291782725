import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	click(e: Event) {
		const t = e.currentTarget as HTMLTableCellElement;
		const field = t.dataset?.field || "";
		this.setField(field);
	}

	setField(field: string) {
		const s = document.getElementById("sort_field") as HTMLSelectElement;
		const index =
			[...s.querySelectorAll("option")].findIndex((o) => o.value === field) ||
			0;
		if (s.selectedIndex === index) {
			const dir = document.getElementById(
				"sort_direction"
			) as HTMLSelectElement;
			if (dir.selectedIndex === 0) {
				dir.selectedIndex = 1;
			} else {
				dir.selectedIndex = 0;
			}
		}
		s.selectedIndex = index;
		s.closest("form")?.requestSubmit();
	}
}
