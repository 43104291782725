import ahoy from "ahoy.js";

ahoy.configure({
	visitsUrl: "/a/visits",
	eventsUrl: "/a/events",
});

document.addEventListener("turbo:load", function () {
	ahoy.trackView();
});
