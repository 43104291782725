import { Controller } from "@hotwired/stimulus";
import { verifyAuditorOverridePassword } from "api/auditor_override_passwords";

export default class extends Controller {
	static values = {
		message: String,
		needsPassword: Boolean,
		overrideType: String,
	};

	declare messageValue: string;
	declare needsPasswordValue: boolean;
	declare overrideTypeValue: string;

	verifyPassword(e: Event) {
		if (this.needsPasswordValue) {
			e.preventDefault();

			const confirmMessage = this.messageValue;
			const form = this.element.closest("form");
			const overrideType = this.overrideTypeValue;

			window.passwordConfirm(confirmMessage).then(({ didSubmit, password }) => {
				if (didSubmit && password.length > 0) {
					verifyAuditorOverridePassword({
						password,
						overrideType,
					}).then(({ error }) => {
						if (error && error.length > 0) {
							window.toast({ text: error, type: "error" });
						} else {
							form?.requestSubmit();
						}
					});
				}
			});
		}
	}
}
