// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "controllers";
import "analytics";
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

let sidebarScrollTop = 0;
document.addEventListener("turbo:before-visit", function () {
	sidebarScrollTop =
		document.querySelector('[data-sidebar-target="sidebar"]')?.scrollTop || 0;
});

document.addEventListener("turbo:render", function () {
	const sel = document.querySelector('[data-sidebar-target="sidebar"]');
	if (sel) sel.scrollTop = sidebarScrollTop;
});
