import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["ticketId", "customerInfo", "techId"];
	declare ticketIdTarget: HTMLInputElement;
	declare customerInfoTarget: HTMLInputElement;
	declare techIdTarget: HTMLInputElement;

	mutate(entries: MutationRecord[]): void {
		entries.forEach((mutation) => {
			if (mutation.type === "attributes" && mutation.attributeName === "src") {
			}
		});
	}

	change(e: Event) {
		document.querySelectorAll("fieldset").forEach((fs) => {
			fs.removeAttribute("disabled");
		});
		setTimeout(() => {
			document.querySelectorAll(".hidden fieldset").forEach((fs) => {
				fs.setAttribute("disabled", "");
			});
		}, 500);
	}

	submit(e: Event) {
		(e.currentTarget as HTMLFormElement).requestSubmit();
	}
}
