import { Controller } from "@hotwired/stimulus";
import { Consumer } from "@rails/actioncable";
import { createConsumer } from "@rails/actioncable";

export default class extends Controller {
	static targets = ["audio"];
	declare audioTarget: HTMLAudioElement;
	declare consumer: Consumer;

	showNotification = () => {
		window.toast({ type: "info", text: "New Part Order" });
	};

	refresh = () => {
		// @ts-ignore
		document.forms?.part_order?.requestSubmit();
	};

	updateTitle = () => {
		const notice = "‼️";
		if (!document.title.includes(notice)) {
			document.title = notice + document.title;
		}
	};

	playSound = () => {
		const shouldPlaySound =
			document.querySelector("[name='sound']:checked")?.id === "sound_on";
		if (shouldPlaySound) {
			this.audioTarget.play();
		}
	};

	notifyNewOrder = () => {
		this.playSound();
		this.updateTitle();
		this.showNotification();
		this.refresh();
	};

	connect = () => {
		const that = this;

		this.consumer = createConsumer();
		this.consumer.subscriptions.create("PartOrdersChannel", {
			received(data: { order_complete: boolean }) {
				if (data.order_complete) {
					that.refresh();
				} else {
					that.notifyNewOrder();
				}
			},
		});
	};

	disconnect(): void {
		this.consumer.disconnect();
	}
}
