import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["submit"];
	declare submitTarget: HTMLInputElement;

	connect() {
		this.submitTarget.classList.add("hidden");
		const form = this.element as HTMLFormElement;
		form.addEventListener("change", () => {
			form.requestSubmit();
		});
	}
}
