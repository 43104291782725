import { Controller } from "@hotwired/stimulus";
import formatDistance from "date-fns/formatDistance";
import { parseISO } from "date-fns";

export default class extends Controller {
	static values = {
		time: String,
	};

	declare timeValue: string;
	declare int: NodeJS.Timer;

	time = () => {
		const newTime = formatDistance(new Date(), parseISO(this.timeValue));
		(this.element as HTMLSpanElement).innerText = newTime + " ago";
	};

	connect() {
		this.time();
		this.int = setInterval(this.time, 1000);
	}

	disconnect(): void {
		clearInterval(this.int);
	}
}
